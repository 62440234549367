<template>
  <v-dialog
    max-width="800px"
    v-model="status"
    scrollable
    persistent
    :overlay="false"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>
        {{ location.display_name ? location.display_name : location.name }}
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog">close</v-icon>
      </v-card-title>
      <v-card-subtitle class="pb-0"
        >Add/Remove users from location
        {{ group ? " group" : null }}</v-card-subtitle
      >
      <v-text-field
        v-model="search"
        class="px-6"
        name="search"
        prepend-inner-icon="search"
        label="Search users"
      ></v-text-field>
      <v-card-text>
        <v-data-table :headers="headers" :items="filteredUsers">
          <template v-slot:item.active="{ item }">
            <v-checkbox
              label=""
              v-model="item.active"
              @change="toggleUser(item, $event)"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" color="primary">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AuthAPI } from '@/clients/auth/auth.api'
import { LocationsAPI } from '@/clients/locations/locationsapi'
export default {

  mounted() {
    if (!this.closedOnce) {
      if (this.$route.query.first_name) {
        this.search = ''
        this.search += this.$route.query.first_name
      }
      if (this.$route.query.last_name) {
        this.search += this.$route.query.last_name
      }
    }
    this.loadUsers()
  },
  data() {
    return {
      headers: [
        { text: 'First Name', value: 'first_name', align: 'left', sortable: true },
        { text: 'Last Name', value: 'last_name', align: 'left', sortable: true },
        { text: 'Email', value: 'email', align: 'left', sortable: true },
        { text: 'Status', value: 'active', align: 'end', sortable: true }
      ],
      users: [],
      search: ''
    }
  },
  props: {
    location: {
      type: Object,
      required: true
    },
    status: {
      default: false
    },
    group: {
      default: false
    },
    closedOnce: {
      default: false
    }
  },

  methods: {
    closeDialog() {
      this.$emit('close-users-dialog')
    },
    async loadUsers() {
      if (!this.group) {
        this.spinner(true)
        let response
        try {
          response = await AuthAPI.getUsersForALocation(this.location.id, null, null, true)
        } catch (error) {
          console.log(error)
          return
        }
        this.users = response.data.payload
        this.spinner(false)
      } else {
        this.spinner(true)
        let response
        try {
          response = await LocationsAPI.getLocationGroupUsers(this.location.id)
        } catch (error) {
          console.log(error)
          return
        }
        this.users = response.data.payload.users
        this.spinner(false)
      }
    },

    async toggleUser(user, event) {
      if (!this.group) {
        if (event) {
          this.addUserToLocation(user, event)
        } else {
          this.removeUserFromLocation(user, event)
        }
      } else {
        if (event) {
          this.addUserToLocationGroup(user, event)
        } else {
          this.removeUserFromLocationGroup(user, event)
        }
      }
    },
    async addUserToLocation(user, event) {
      try {
        await AuthAPI.addUserToLocation(user.id, { 'location_ids': [this.location.id] })
      } catch (error) {
        console.log(error)
        user.active = !event
      }
    },
    async removeUserFromLocation(user, event) {
      try {
        await AuthAPI.deleteUserFromLocation(user.id, { 'location_ids': [this.location.id] })
      } catch (error) {
        console.log(error)
        user.active = !event
      }
    },
    async addUserToLocationGroup(user, event) {
      try {
        await LocationsAPI.addUserToLocationGroup(user.id, { location_group_ids: [this.location.id] })
      } catch (error) {
        console.log(error)
        user.active = !event
      }
    },
    async removeUserFromLocationGroup(user, event) {
      try {
        await LocationsAPI.removeUserFromLocationGroup(this.location.id, user.id)
      } catch (error) {
        console.log(error)
        user.active = !event
      }
    }
  },
  computed: {
    filteredUsers() {
      if (this.search === '') {
        return this.users
      }
      return this.users.filter(i => {
        let str = i.first_name + i.last_name
        return str.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    }
  }
}
</script>

<style>
</style>
